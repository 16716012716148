<template>
    <page-content>

        <common-table
                ref="table"
                path="web/beian/points"
                :queryParams="queryParams"
                :columns="columns">

            <template slot="search">
                <!--        <a-button  ghost type="primary" @click="add">新增</a-button>-->
                <a-radio-group v-model="queryParams.identityId" @change="$refs.table.handleReset()">
                    <a-radio-button value="IDENTITY_ID_MANAGER">管理团队</a-radio-button>
                    <a-radio-button value="IDENTITY_ID_XU_HAO_BIN">陆志敏</a-radio-button>
                </a-radio-group>
            </template>

            <template slot="logo" slot-scope="{record}">
                <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
            </template>


            <template slot="operation" slot-scope="{record}">
                <action-message @click="viewComment(record)"></action-message>
                <action-edit @click="edit(record)"></action-edit>
                <action-delete @click="remove(record)"></action-delete>
            </template>

        </common-table>

        <BeianConfirm ref="beianConfirm"></BeianConfirm>

    </page-content>
</template>
<script>
    export default {
        components: {},
        data() {
            return {
                queryParams:{
                    identityId:"IDENTITY_ID_MANAGER"
                }
            }
        },

        computed: {
            columns() {
                return [
                    {
                        title:"推广产品",
                        dataIndex: "productName"
                    },
                    {
                        title: '购买价额',
                        dataIndex: 'productPrice',
                        customRender(text){
                            return text/100 + " 元"
                        }
                    },
                    {
                        title: '产品佣金',
                        dataIndex: 'commission',
                        customRender(text){
                            return text/100 + " 元"
                        }
                    },
                    {
                        title: '获得积分',
                        dataIndex: 'points',
                        customRender(text){
                            return "+"+text
                        }
                    },
                    {
                        title: '当前积分',
                        dataIndex: 'currentPoints'
                    },

                    {
                        title: '时间',
                        dataIndex: 'createdAt'
                    },

                ]
            }
        },
        methods: {
            add() {
                this.$refs.newsEdit.show()
            },
            edit(record) {
                this.$refs.newsEdit.show(record)
            },
            handleSetStatus(record, status) {
                //同意，需要确认
                if(status==1){
                    this.$refs.beianConfirm.open(record)
                    return
                }

                this.$put("web/beian/record/status",{
                    id:record.id,
                    status
                }).then(suc=>{
                    this.getList()
                })

            },
            getList() {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

    .news-logo {
        width: 60px;
        height: 60px;
    }
</style>
